'use client'

import { useContext } from 'react'
//
import { AuthorizationContext } from 'src/auth/context/authorization/authorization-context'
import { AuthorizationContextType } from 'src/auth/types'

// ----------------------------------------------------------------------

export const useAuthorizationContext = (): AuthorizationContextType => {
  const context = useContext(AuthorizationContext)

  if (!context) {
    throw new Error('useAuthorizationContext context must be use inside AuthorizationProvider')
  }

  return context
}
